<template>
  <div>
<!--    <nav class="nav nav-pills nav-justified mb-2">-->
<!--      <a :class="['gray nav-item nav-link', {'active_custom': type === 'sms'}, {'disabled-type' : auditRecord.sms === null}]" href="javascript:void(0);" @click="setTypeTo('sms', auditRecord.sms === null)">SMS</a>-->
<!--      <a :class="['gray nav-item nav-link', {'active_custom': type === 'isps'}, {'disabled-type' : auditRecord.isps === null}]" href="javascript:void(0);" @click="setTypeTo('isps', auditRecord.isps === null)">ISPS</a>-->
<!--      <a :class="['gray nav-item nav-link', {'active_custom': type === 'cyber'}, {'disabled-type' : auditRecord.cyber === null}]" href="javascript:void(0);" @click="setTypeTo('cyber', auditRecord.cyber === null)">Cyber</a>-->
<!--    </nav>-->

<!--    <template>-->
<!--      <app-loading></app-loading>-->
<!--    </template>-->

    <template v-if="!isEditing">
      <div class="card p-3 shadow-sm">
      <div class="row justify-content-between">
        <div class="col-auto">
          <h5 class="e-text-red">
            INTERNAL AUDIT ({{ type.toUpperCase() }})
          </h5>
        </div>
        <div class="col-auto">
            <button class="e-btn e-btn-green ml-2 px-3" type="button" @click="startEditing">
              Edit Report
            </button>

          <div class="float-right ml-2 dropdown dropright">
            <button id="downloadIaDocuments" aria-expanded="false" aria-haspopup="true" class="mr-2 e-btn e-btn-blue-gray force-white-all"
                    data-toggle="dropdown">
              <font-awesome-icon class="mr-1" icon="download"/>
              Download Document
            </button>
            <div aria-labelledby="actionAIRDropdown" class="dropdown-menu">
              <button class="dropdown-item text-secondary font-weight-bolder" type="button" @click="downloadDocument('appointment_plan')">
                <font-awesome-icon icon="file-pdf"/>
                Audit appointments and Plan
              </button>
              <button class="dropdown-item text-secondary font-weight-bolder" type="button" @click="downloadDocument('attendance')">
                <font-awesome-icon icon="file-pdf"/>
                Attendance
              </button>
              <button class="dropdown-item text-secondary font-weight-bolder" type="button" @click="downloadDocument('corrective_actions')">
                <font-awesome-icon icon="file-pdf"/>
                Corrective Actions Report
              </button>
              <button class="dropdown-item text-secondary font-weight-bolder" type="button" @click="downloadDocument('non_conformity')">
                <font-awesome-icon icon="file-pdf"/>
                Non-Conformity/Observation Note for {{ type.toUpperCase() }}
              </button>
              <button class="dropdown-item text-secondary font-weight-bolder" type="button" @click="downloadDocument('internal_audit_report')">
                <font-awesome-icon icon="file-pdf"/>
                Internal Audit Report ({{ type.toUpperCase() }})
              </button>
              <button class="dropdown-item text-secondary font-weight-bolder" type="button" @click="downloadDocument('all')">
                <font-awesome-icon icon="file-pdf"/>
                All For ({{ type.toUpperCase() }})
              </button>
            </div>
          </div>
        </div>
      </div>
      <h6 class="font-weight-bolder">Appointment and Plan Report</h6>
      <table class="w-100">
        <tr>
          <td style="width: 12%;">Report Number</td>
          <td style="width: 50%;">{{ auditRecord[type]?.report_number }}</td>
          <td style="width: 12%;">
            Status
            <font-awesome-icon
              icon="question-circle"
              data-toggle="modal"
              style="cursor:pointer"
              data-target="#requirementsBeforeClosing">
            </font-awesome-icon>
          </td>
          <td v-if="editTypeStatus">
            <div class="row align-content-center align-items-center">
              <div class="col-8">
                <select class="form-control form-control-sm" v-model="auditRecord[type].report_status_id">
                  <template v-for="reportStatusKey in reportTypeStatusKeys">
                    <option v-if="reportStatusKey.visible" :value="reportStatusKey.id" :disabled="reportStatusKey.disabled">
                      {{ reportStatusKey.name }}
                    </option>
                  </template>
                </select>
              </div>
              <div class="col-auto">
                <button type="button" class="btn btn-success btn-xs mr-2" :disabled="auditRecord[type]?.report_status_id === selectedCompanyAuditRecord[type]?.report_status_id"
                        @click="saveTypeStatus">
                  <font-awesome-icon icon="save" class="force-white-all">

                  </font-awesome-icon>
                  Save
                </button>
                <button type="button" class="btn btn-danger btn-xs" @click="cancelEditTypeStatus">
                  <font-awesome-icon icon="ban" class="force-white-all"></font-awesome-icon>
                  Cancel
                </button>
              </div>
            </div>
          </td>
          <td v-else>
            <button type="button" class="'e-btn e-btn-xs px-5" :class="['bg-'+convertReportStatus(auditRecord[type]?.report_status_id).color]"
                    @click="editTypeStatus = true">{{ convertReportStatus(auditRecord[type]?.report_status_id).name ?? 'NEW' }}
            </button>
          </td>
        </tr>
        <tr>
          <td style="width: 12%;">Company Name <sub class="lead e-text-red font-weight-bold">*</sub></td>
          <td style="width: 50%;">{{ companyDetail.name? companyDetail.name : ''}}</td>
          <td style="width: 12%;">
            Date of Audit <sub class="lead e-text-red font-weight-bold">*</sub>
          </td>
          <td>
            {{ auditRecord[type]?.audit_date_human[0] === auditRecord[type]?.audit_date_human[1] ? auditRecord[type]?.audit_date_human[0] : auditRecord[type]?.audit_date_human[0] + ' - ' + auditRecord[type]?.audit_date_human[1]}}
          </td>
        </tr>
        <tr>
<!--          <td style="width: 12%;">Audit Type 2 <sub class="lead e-text-red font-weight-bold">*</sub></td>-->
<!--          <td style="width: 50%;"><b>{{ convertAuditType2IntoType(auditRecord[type]?.audit_type_2 ?? []) }}</b></td>-->
          <td style="width: 12%;">
            Appointment Date <sub class="lead e-text-red font-weight-bold">*</sub>
          </td>
          <td>
            {{ auditRecord[type]?.appointment_date_human }}
          </td>
          <td style="width: 12%;">Type of Audit <sub class="lead e-text-red font-weight-bold">*</sub></td>
          <td style="width: 50%;"><b>{{ convertAuditType3IntoType(auditRecord[type]?.audit_type_3 ?? []) }}</b></td>
        </tr>
        <tr>
          <td style="width: 12%;">
            Person Responsible
          </td>
          <td>
            {{ auditRecord[type]?.person_responsible }}
          </td>
          <td style="width: 12%;">
            Extension
          </td>
          <td>
            {{ auditRecord[type]?.extension === 0 ? 'NO' : 'YES'}}
          </td>
        </tr>

        <tr>
          <td style="width: 12%;">
            Audit Place
          </td>
          <td>
            {{ auditRecord[type]?.audit_place }}
          </td>
          <template v-if="auditRecord[type]?.extension === 1">
            <td style="width: 12%;">
              Extension Date <sub class="lead e-text-red font-weight-bold">*</sub>
            </td>
            <td>
              {{ auditRecord[type]?.extension_date_human }}
            </td>
          </template>
        </tr>

        <tr>
          <td style="width: 19%;">Lead Auditor <sub class="lead e-text-red font-weight-bold">*</sub></td>
          <td style="width: 40%;">
            <multi-select-with-search
              :key="type + '_lead_' + isEditing"
              id="lead-data"
              :items="leadAndNonLeadAuditors" :entity="'Lead'"
              :selected="auditRecord[type].lead_ids"
              :edit-mode="false"
            >
            </multi-select-with-search>
          </td>
          <td style="width: 15%;">Auditors</td>
          <td>
            <multi-select-with-search
              :key="type + '_member_' + isEditing"
              id="members-data"
              :items="leadAndNonLeadAuditors" :entity="'Members'"
              :selected="auditRecord[type].members_ids"
              :edit-mode="false"
            >
            </multi-select-with-search>
          </td>
        </tr>
      </table>
<!--      <table class="w-100">-->
<!--        <tr>-->
<!--          <td style="width: 19%;">Master</td>-->
<!--          <td style="width: 40%;">-->
<!--            <p class="p-0 m-0" v-for="master in master[type]">{{ master.last_name }}, {{ master.first_name }}-->
<!--              {{ master.middle_name }}-->
<!--            </p>-->
<!--          </td>-->
<!--          <td style="width: 15%;">Deck Dept Head (C/O)</td>-->
<!--          <td style="width: 40%;">-->
<!--            <p class="p-0 m-0" v-for="deck_dept_head in deck_dept_head[type]">{{ deck_dept_head.last_name }}, {{ deck_dept_head.first_name }}-->
<!--              {{ deck_dept_head.middle_name }}-->
<!--            </p>-->
<!--          </td>-->
<!--        </tr>-->
<!--        <tr>-->
<!--          <td style="width: 19%;">Eng Dept Head (C/E)</td>-->
<!--          <td style="width: 40%;">-->
<!--            <p class="p-0 m-0" v-for="eng_dept_head in eng_dept_head[type]">{{ eng_dept_head.last_name }}, {{ eng_dept_head.first_name }}-->
<!--              {{ eng_dept_head.middle_name }}-->
<!--            </p>-->
<!--          </td>-->
<!--          <td style="width: 15%;"></td>-->
<!--          <td></td>-->
<!--        </tr>-->
<!--      </table>-->
    </div>
    </template>

    <template v-else>
      <div class="card p-3 shadow-sm">
      <div class="row justify-content-between">
        <div class="col-auto">
          <h5 class="e-text-red">
            INTERNAL AUDIT ({{ type.toUpperCase() }})
          </h5>
        </div>
        <div class="col-auto">
            <button class="e-btn e-btn-outlined-red ml-2" type="button" @click="cancel">Cancel</button>
            <button class="e-btn e-btn-blue ml-2" type="submit" @click="saveChanges" :class="hasChanges === false ? 'e-btn-blue-disabled' : ''" :disabled="hasChanges === false">Save</button>
        </div>
      </div>
      <h6 class="font-weight-bolder">Appointment and Plan Report</h6>
      <table class="w-100">
        <tr>
          <td style="width: 12%;">Report Number</td>
          <td style="width: 50%;">{{ auditRecord[type]?.report_number }}</td>
          <td style="width: 12%;">
            Status
            <font-awesome-icon
              icon="question-circle"
              data-toggle="modal"
              style="cursor:pointer"
              data-target="#requirementsBeforeClosing">
            </font-awesome-icon>
          </td>
          <td v-if="editTypeStatus">
            <div class="row align-content-center align-items-center">
              <div class="col-8">
                <select class="form-control form-control-sm" v-model="auditRecord[type].report_status_id">
                  <template v-for="reportStatusKey in reportTypeStatusKeys">
                    <option v-if="reportStatusKey.visible" :value="reportStatusKey.id" :disabled="reportStatusKey.disabled">
                      {{ reportStatusKey.name }}
                    </option>
                  </template>
                </select>
              </div>
              <div class="col-auto">
                <button type="button" class="btn btn-success btn-xs mr-2" :disabled="auditRecord[type]?.report_status_id === selectedCompanyAuditRecord[type]?.report_status_id"
                        @click="saveTypeStatus">
                  <font-awesome-icon icon="save" class="force-white-all">

                  </font-awesome-icon>
                  Save
                </button>
                <button type="button" class="btn btn-danger btn-xs" @click="cancelEditTypeStatus">
                  <font-awesome-icon icon="ban" class="force-white-all"></font-awesome-icon>
                  Cancel
                </button>
              </div>
            </div>
          </td>
          <td v-else>
            <button type="button" class="'e-btn e-btn-xs px-5" :class="['bg-'+convertReportStatus(auditRecord[type]?.report_status_id).color]"
                    @click="editTypeStatus = true">{{ convertReportStatus(auditRecord[type]?.report_status_id).name ?? 'NEW' }}
            </button>
          </td>
        </tr>
        <tr>
          <td style="width: 12%;">Company Name <sub class="lead e-text-red font-weight-bold">*</sub></td>
          <td style="width: 50%;">{{ companyDetail.name? companyDetail.name : ''}}</td>
          <td style="width: 12%;">
            Date of Audit <sub class="lead e-text-red font-weight-bold">*</sub>
          </td>
          <td>
            <date-picker v-model="auditRecord[type].audit_date" :lang = 'en' type="date" valueType="format" range :editable="false"></date-picker>
          </td>
        </tr>
        <tr>
          <td style="width: 12%;">
            Appointment Date <sub class="lead e-text-red font-weight-bold">*</sub>
          </td>
          <td>
            <date-picker v-model="auditRecord[type].appointment_date" :lang = 'en' type="date" valueType="format" :editable="false" :clearable="false" style="width: 100%"></date-picker>
          </td>
          <td style="width: 12%;">Type of Audit <sub class="lead e-text-red font-weight-bold">*</sub></td>
          <td style="width: 50%;">
            <div class="form-group row no-gutters mb-1">
              <div class="form-check form-check-inline">
                <input class="form-check-input checkbox-input" type="checkbox" value="" id="audit_type3_onsite" @click="setAuditTypeThree(1)" :checked="auditRecord[type]?.audit_type_3.includes(1)">
                <label for="audit_type3_onsite" class="font-weight-bold col-m-3 col-form-label no-select cursor-pointer">Onsite</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input checkbox-input" type="checkbox" value="" id="audit_type3_remote" @click="setAuditTypeThree(2)" :checked="auditRecord[type]?.audit_type_3.includes(2)">
                <label for="audit_type3_remote" class="font-weight-bold col-m-3 col-form-label no-select cursor-pointer">Remote</label>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td style="width: 12%;">
            Person Responsible
          </td>
          <td>
            <input class="form-control form-control-sm" type="text" v-model="auditRecord[type].person_responsible">
          </td>
          <td style="width: 12%;">
            Extension
          </td>
          <td>
            <select-with-search
              :key="type + '_select-search-country_' + auditRecord[type].extension"
              v-model="auditRecord[type].extension"
              :current-selected-item-id="auditRecord[type].extension"
              @change="auditRecord[type].extension_date = null"
              :include-search-input="false"
              :key-item-value="'id'"
              :key-display-text="'name'"
              :items="[
                {
                  id : 0,
                  name : 'NO'
                },
                {
                  id : 1,
                  name : 'YES'
                },
              ]"
              :element-class="'form-control form-control-sm text-uppercase'"
            />

          </td>
        </tr>
        <tr>
          <td style="width: 12%;">Audit Place <sub class="lead e-text-red font-weight-bold">*</sub></td>
          <td style="width: 50%;">
            <input type="text" id="last_name" class="form-control form-control-sm" required v-model="auditRecord[type].audit_place">
          </td>
          <td style="width: 12%;">
            <span v-if="auditRecord[type]?.extension === 1">
              Extension Date <sub class="lead e-text-red font-weight-bold">*</sub>
            </span>
          </td>
          <td>
            <input class="form-control form-control-sm" type="date" v-if="auditRecord[type]?.extension === 1" style="display: inline; width: fit-content;" v-model="auditRecord[type].extension_date">
          </td>
        </tr>

        <tr>
          <td style="width: 19%;">Lead Auditor <sub class="lead e-text-red font-weight-bold">*</sub></td>
          <td style="width: 40%;">
            <multi-select-with-search
              :key="type + '_lead_' + isEditing"
              id="lead-data"
              :items="leadAndNonLeadAuditors" :entity="'Lead'"
              @doneSelected="setLeadMembers"
              :selected="auditRecord[type].lead_ids"
            >
            </multi-select-with-search>
          </td>
          <td style="width: 15%;">Auditors</td>
          <td>
            <multi-select-with-search
              :key="type + '_member_' + isEditing"
              id="members-data"
              :items="leadAndNonLeadAuditors" :entity="'Members'"
              @doneSelected="setNonLeadMembers"
              :selected="auditRecord[type].members_ids"
            >
            </multi-select-with-search>
          </td>
        </tr>

      </table>
<!--      <table class="w-100">-->
<!--        <tr>-->
<!--          <td style="width: 19%;">Master</td>-->
<!--          <td style="width: 40%;">-->
<!--            <p class="p-0 m-0" v-for="master in master[type]">{{ master.last_name }}, {{ master.first_name }}-->
<!--              {{ master.middle_name }}-->
<!--            </p>-->
<!--          </td>-->
<!--          <td style="width: 15%;">Deck Dept Head (C/O)</td>-->
<!--          <td style="width: 40%;">-->
<!--            <p class="p-0 m-0" v-for="deck_dept_head in deck_dept_head[type]">{{ deck_dept_head.last_name }}, {{ deck_dept_head.first_name }}-->
<!--              {{ deck_dept_head.middle_name }}-->
<!--            </p>-->
<!--          </td>-->
<!--        </tr>-->
<!--        <tr>-->
<!--          <td style="width: 19%;">Eng Dept Head (C/E)</td>-->
<!--          <td style="width: 40%;">-->
<!--            <p class="p-0 m-0" v-for="eng_dept_head in eng_dept_head[type]">{{ eng_dept_head.last_name }}, {{ eng_dept_head.first_name }}-->
<!--              {{ eng_dept_head.middle_name }}-->
<!--            </p>-->
<!--          </td>-->
<!--          <td style="width: 15%;"></td>-->
<!--          <td></td>-->
<!--        </tr>-->
<!--      </table>-->
    </div>
    </template>

    <nav class="navbar navbar-expand-lg mt-2" id="ModelNav">
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav"
              aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link class="nav-link" :to="{
              name: 'CompanyInternalAuditReport'
            }">
              <span>Internal Audit Report</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link white" :to="constructRouterTo('CompanyNonConformity')"
            >
              <span>Non-Conformity/Observation/Opportunity For Improvement</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link white" :to="{name: 'CompanyIaCorrectiveActionReport'}"
            >
              <span>Corrective Action Report</span>
            </router-link>
          </li>
<!--          <li class="nav-item">-->
<!--            <router-link-->
<!--              class="nav-link white" :to="{name: 'CompanyIAShipParticular' }"-->
<!--            >-->
<!--              <span>Ship's Particular</span>-->
<!--            </router-link>-->
<!--          </li>-->
        </ul>
      </div>
    </nav>
    <hr style="border-top: 2px solid #e7e7e7; margin-top: -2px"/>
    <router-view
      :key="'nav-bar' + '_' + $route.params.vessel_id + '_' + $route.params.id + '_' + $route.params.type"
    ></router-view>

    <div class="modal fade" data-backdrop="static" data-keyboard="false" id="viewAuditorsList" tabindex="-2" :aria-labelledby="'viewAuditorsList' + 'Label'" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-md">
        <form class="modal-content" @submit.prevent="">
          <div class="modal-header">
            <div class="modal-title w-100 text-center">
              <h5 class="font-weight-bold">{{ auditorTypeToView === 'leaders' ? 'Lead' : 'Member' }} Auditors List</h5>
            </div>
          </div>
          <div class="modal-body">
            <template v-if = "auditorTypeToView === 'leaders'">
              <span class="badge badge-pill badge-secondary mr-1 p-2" v-for="leader in auditRecord[type]?.leaders">
              {{ leader.name }}
            </span>
            </template>
            <template v-else>
            <span class="badge badge-pill badge-secondary mr-1 p-2" v-for="member in auditRecord[type]?.members">
              {{ member.name }}
            </span>
            </template>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-sm btn-primary" @click="closeViewAuditorList">OK</button>
          </div>
        </form>
      </div>
    </div>
    <PopperModal popper-id="requirementsBeforeClosing">
      <template #popperHeader>Required Fields Before Close</template>
      <template #popperBody>
        <table style="width: 100%;">
          <tr>
            <th colspan="3" class="h6">INTERNAL AUDIT ({{ type.toUpperCase() }})</th>
          </tr>
          <tr>
            <td style="width: 25%">
              Audit Type 2:
            </td>
            <td style="width: 25px;">
              <font-awesome-icon
                :icon="selectedCompanyAuditRecord[type.toLowerCase()]?.audit_type_2.length > 0 ? 'check' : 'times'"
                :class="selectedCompanyAuditRecord[type.toLowerCase()]?.audit_type_2.length > 0 ? 'e-text-green' : 'e-text-red'"/>
            </td>
            <td :class="selectedCompanyAuditRecord[type.toLowerCase()]?.audit_type_2.length > 0 ? 'e-bg-white' : 'e-bg-red-light-7'">
              {{ convertAuditType2IntoType(selectedCompanyAuditRecord[type]?.audit_type_2 ?? []).length === 0 ? '' : convertAuditType2IntoType(selectedCompanyAuditRecord[type.toLowerCase()]?.audit_type_2)}}
            </td>
          </tr>
          <tr>
            <td style="width: 25%">
              Type of Audit:
            </td>
            <td style="width: 25px;">
              <font-awesome-icon
                :icon="selectedCompanyAuditRecord[type.toLowerCase()]?.audit_type_3.length > 0 ? 'check' : 'times'"
                :class="selectedCompanyAuditRecord[type.toLowerCase()]?.audit_type_3.length > 0 ? 'e-text-green' : 'e-text-red'"/>
            </td>
            <td :class="selectedCompanyAuditRecord[type.toLowerCase()]?.audit_type_3.length > 0 ? 'e-bg-white' : 'e-bg-red-light-7'">
              {{ convertAuditType3IntoType(selectedCompanyAuditRecord[type]?.audit_type_3 ?? []).length === 0 ? '' : convertAuditType3IntoType(selectedCompanyAuditRecord[type.toLowerCase()]?.audit_type_3)}}
            </td>
          </tr>
          <tr>
            <td style="width: 25%">
              Date of Audit:
            </td>
            <td style="width: 25px;">
              <font-awesome-icon
                :icon="selectedCompanyAuditRecord[type.toLowerCase()]?.audit_date_start.human !== null && selectedCompanyAuditRecord[type.toLowerCase()]?.audit_date_end.human !== null ? 'check' : 'times'"
                :class="selectedCompanyAuditRecord[type.toLowerCase()]?.audit_date_start.human !== null && selectedCompanyAuditRecord[type.toLowerCase()]?.audit_date_end.human !== null ? 'e-text-green' : 'e-text-red'"/>
            </td>
            <td :class="selectedCompanyAuditRecord[type.toLowerCase()]?.audit_date_start.human !== null && selectedCompanyAuditRecord[type.toLowerCase()]?.audit_date_end.human !== null ? 'e-bg-white' : 'e-bg-red-light-7'">
              {{ selectedCompanyAuditRecord[type.toLowerCase()]?.audit_date_start.human === selectedCompanyAuditRecord[type.toLowerCase()]?.audit_date_end.human ? selectedCompanyAuditRecord[type.toLowerCase()]?.audit_date_start.human : selectedCompanyAuditRecord[type.toLowerCase()]?.audit_date_start.human + ' - ' + selectedCompanyAuditRecord[type.toLowerCase()]?.audit_date_end.human }}
            </td>
          </tr>
          <tr>
            <td style="width: 25%">
              Appointment Date:
            </td>
            <td style="width: 25px;">
              <font-awesome-icon
                :icon="selectedCompanyAuditRecord[type.toLowerCase()]?.appointment_date.human !== null ? 'check' : 'times'"
                :class="selectedCompanyAuditRecord[type.toLowerCase()]?.appointment_date.human !== null ? 'e-text-green' : 'e-text-red'"/>
            </td>
            <td :class="selectedCompanyAuditRecord[type.toLowerCase()]?.appointment_date.human !== null ? 'e-bg-white' : 'e-bg-red-light-7'">
              {{ selectedCompanyAuditRecord[type.toLowerCase()]?.appointment_date.human }}
            </td>
          </tr>
          <tr v-if="selectedCompanyAuditRecord[type.toLowerCase()]?.is_extended !== 0">
            <td style="width: 25%">
              Extension Date:
            </td>
            <td style="width: 25px;">
              <font-awesome-icon
                :icon="selectedCompanyAuditRecord[type.toLowerCase()]?.extended_date.human !== null ? 'check' : 'times'"
                :class="selectedCompanyAuditRecord[type.toLowerCase()]?.extended_date.human !== null ? 'e-text-green' : 'e-text-red'"/>
            </td>
            <td :class="selectedCompanyAuditRecord[type.toLowerCase()]?.extended_date.human !== null ? 'e-bg-white' : 'e-bg-red-light-7'">
              {{ selectedCompanyAuditRecord[type.toLowerCase()]?.extended_date.human }}
            </td>
          </tr>
          <tr>
            <td style="width: 25%">
              Country:
            </td>
            <td style="width: 25px;">
              <font-awesome-icon
                :icon="selectedCompanyAuditRecord[type.toLowerCase()]?.country_id !== null ? 'check' : 'times'"
                :class="selectedCompanyAuditRecord[type.toLowerCase()]?.country_id !== null ? 'e-text-green' : 'e-text-red'"/>
            </td>
            <td :class="selectedCompanyAuditRecord[type.toLowerCase()]?.country_id !== null ? 'e-bg-white' : 'e-bg-red-light-7'">
              {{ filterDataById(countryKeys, selectedCompanyAuditRecord[type.toLowerCase()]?.country_id).length > 0 ? filterDataById(countryKeys, selectedCompanyAuditRecord[type.toLowerCase()]?.country_id)[0].name : '' }}
            </td>
          </tr>
          <tr>
            <td style="width: 25%">
              Lead Auditor:
            </td>
            <td style="width: 25px;">
              <font-awesome-icon
                :icon="filterAuditorsByPosition(selectedCompanyAuditRecord[type.toLowerCase()]?.auditors ?? []).leaders.length !== 0 ? 'check' : 'times'"
                :class="filterAuditorsByPosition(selectedCompanyAuditRecord[type.toLowerCase()]?.auditors ?? []).leaders.length !== 0 ? 'e-text-green' : 'e-text-red'"/>
            </td>
            <td :class="filterAuditorsByPosition(selectedCompanyAuditRecord[type.toLowerCase()]?.auditors ?? []).leaders.length !== 0 ? 'e-bg-white' : 'e-bg-red-light-7'">
              <template  v-for="(leader, index) in filterAuditorsByPosition(selectedCompanyAuditRecord[type.toLowerCase()]?.auditors ?? []).leaders">
                <div v-if="index < 8" class="badge badge-pill badge-secondary mr-1 p-2 cursor-pointer mb-1">{{ leader.name }}</div>
              </template>
            </td>
          </tr>
        </table>
      </template>
      <template #popperFooter>
        <small>NOTE: Details/Remarks are too long to display so we just put where it could be found.</small>
      </template>
    </PopperModal>
  </div>
</template>

<script>
import {AlertService} from "@/services/AlertService";
import AppLoading from "@/components/elements/AppLoading.vue";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import AuditorMixin from "@/mixins/AuditorMixin";
import {DataService} from "@/services/DataService";
import MultiSelectWithSearch from "@/components/MultiSelectWithSearch.vue";
import PopperModal from "@/components/elements/PopperModal.vue";
import SelectWithSearch from "@/components/common/SelectWithSearch.vue";
import {mapActions} from "vuex";
import requestLoaderMixin from "@/mixins/requestLoaderMixin";
import CompanyAuditRecordMixin from "@/mixins/CompanyAuditRecordMixin";

export default {
  name: 'CompanyAuditMain',
  components: {SelectWithSearch, PopperModal, MultiSelectWithSearch, AppLoading, DatePicker},
  mixins : [CompanyAuditRecordMixin, AuditorMixin, requestLoaderMixin],
  data(){
    return {
      isEditing: false,
      extension: 'no',
      dateOfAudit: [new Date('31 OCTOBER 2023'), '2023-10-31'],
      appointmentDate: '2023-10-30',
      auditRecord : {
        sms : null,
        isps : null,
        cyber : null
      },
      master : {
        sms : null,
        isps : null,
        cyber : null
      },
      deck_dept_head : {
        sms : null,
        isps : null,
        cyber : null
      },
      eng_dept_head : {
        sms : null,
        isps : null,
        cyber : null
      },
      auditorTypeToView : 'leaders',
      editTypeStatus : false,
      swalOptions: {
        'title': 'Sweet Alert Title',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      },
    }
  },
  methods: {
    ...mapActions([
      'downloadCompanyAuditDocument'
    ]),
    async downloadDocument(documentType) {
      this.showLoaderRequest('DOWNLOADING ' + documentType.toUpperCase() + 'FILE, PLEASE WAIT...');

      let downloadResponse = await this.downloadCompanyAuditDocument({
        audit_id : this.$route.params.id,
        audit_type : this.type,
        file : documentType
      });
      if (downloadResponse.result === false) {
        return AlertService.errorAlert(downloadResponse.message, 'DOWNLOADING OF ' + documentType.toUpperCase() + ' FOR ' + this.type.toUpperCase());
      }
      this.hideLoaderRequest();
      const url = window.URL.createObjectURL(new Blob([downloadResponse.data],{type:'application/pdf'}))
      const filename = documentType.toUpperCase() + `_${Date.now()}.pdf`
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      AlertService.successAlert('Downloaded successfully', 'DOWNLOADING OF ' + documentType.toUpperCase() + ' FOR ' + this.type.toUpperCase());
    },
    constructRouterTo(tabName) {
      if (tabName === 'CompanyNonConformity') {
        const ncrChildrenRoutes = ['CompanyNcrMainDetails', 'CompanyObjectiveEvidenceDetail', 'CompanyOEMainDetails', 'CompanyOEAttachments'];

        return {
          name: ncrChildrenRoutes.includes(this.$route.name) === true ? this.$route.name : tabName,
          params : this.constructParams(tabName)
        }
      }
    },
    constructParams(tabName) {
      if (tabName === 'CompanyNonConformity') {
        if (this.$route.params.ncr_id === undefined) {
          return {
            ncr_id : 'index',
            ncr_type : this.$route.params.ncr_type !== undefined ? this.$route.params.ncr_type : 'ncr'
          }
        }
        return this.$route.params;
      }
    },
    startEditing() {
      this.isEditing = true
    },
    async doUpdate(typeStatusOnly = false) {
      const auditDateHasChanges = this.auditDateHasChanges;
      let auditRecord = {
        id : this.$route.params.id
      };
      auditRecord[this.type] = Object.assign({}, this.auditRecord[this.type]);
      auditRecord[this.type] = this.removeUnnecessaryKey(auditRecord[this.type], [
        'appointment_date_human',
        'audit_date_human',
        'leaders',
        'members',
      ]);

      let updateResponse = await this.updateCompanyAuditRecord(auditRecord);
      if (updateResponse.result === false) {
        return AlertService.errorAlert(updateResponse.message, 'UPDATING OF AUDIT RECORD FOR ' + this.type.toUpperCase())
      }
      await this.getCompanyAuditRecordById(this.$route.params.id);
      this.setAuditReport();


      if (typeStatusOnly === false) {
        this.isEditing = false
        await AlertService.successAlert('Updated successfully', 'UPDATING OF AUDIT RECORD FOR ' + this.type.toUpperCase());


        if (auditDateHasChanges === true) {
          await this.delay(1000);
          swal.fire({...this.swalOptions, ...{title: 'CHANGES HAS BEEN MADE IN AUDIT DUE, ESTABLISHING CONNECTION TO CMS'}});
          swal.showLoading();
          // await this.requestAndSetDataFromCms(this.type);
          swal.close();
        }
      } else {
        this.editTypeStatus = false;
        await AlertService.successAlert('Updated successfully', 'UPDATING OF STATUS FOR ' + this.type.toUpperCase())
        await this.setReportTypeStatusKeys(this.auditRecord);
      }

    },
    async saveChanges() {
      if (await AlertService.confirmUpdateAlert("Do you want to update ? ")) {
        await this.doUpdate();
      }
    },
    async cancel() {
      if (this.hasChanges === false) {
        this.isEditing = false;
        this.setAuditReport();
        return;
      }
      if (await AlertService.cancelAlert()) {
        this.isEditing = false;
        this.setAuditReport();
      }
    },

    async setTypeTo(arg, disabled) {
      if (this.$route.params.type === arg) {
        return;
      }
      if (disabled === false) {
        if (this.hasChanges === true) {
          if (await AlertService.cancelChanges()) {
            await this.$router.replace({name: this.$route.name, params: {
                type: arg
              }});
            this.type = arg
            this.setAuditReport();
            this.editTypeStatus = false;
          }
        } else {
          await this.$router.replace({name: this.$route.name, params: {
              type: arg
            }});
          this.type = arg;
          this.editTypeStatus = false;
        }
      }
    },
    setAuditReport() {
      if (this.selectedCompanyAuditRecord.sms !== null) {
        this.auditRecord.sms = this.setTypeValues(this.selectedCompanyAuditRecord.sms);
        this.audit_main_types[0].enabled = true;
      }

      if (this.selectedCompanyAuditRecord.isps !== null) {
        this.auditRecord.isps = this.setTypeValues(this.selectedCompanyAuditRecord.isps);
        this.audit_main_types[1].enabled = true;
      }

      if (this.selectedCompanyAuditRecord.cyber !== null) {
        this.auditRecord.cyber = this.setTypeValues(this.selectedCompanyAuditRecord.cyber);
        this.audit_main_types[2].enabled = true;
      }
    },
    setInitialActiveType() {
      this.type = this.$route.params.type;
    },
    viewAuditorsList(auditorType) {
      this.auditorTypeToView = auditorType;
      $('#viewAuditorsList').modal('show');
    },
    closeViewAuditorList() {
      $('#viewAuditorsList').modal('hide');
    },
    setAuditTypeTwo(type) {
      this.auditRecord[this.type] = DataService.pushOrRemoveInData(this.auditRecord[this.type], 'audit_type_2', type);
    },
    setAuditTypeThree(type) {
      this.auditRecord[this.type] = DataService.pushOrRemoveInData(this.auditRecord[this.type], 'audit_type_3', type);
    },
    setLeadMembers(leadMemberIds) {
      this.auditRecord[this.type].lead_ids = leadMemberIds.selected.length > 0 ? leadMemberIds.selected : [];
    },
    setNonLeadMembers(nonLeadMemberIds) {
      this.auditRecord[this.type].members_ids = nonLeadMemberIds.selected.length > 0 ? nonLeadMemberIds.selected : [];
    },
    cancelEditTypeStatus() {
      this.editTypeStatus = false;
      this.auditRecord[this.type].report_status_id = this.selectedCompanyAuditRecord[this.type]?.report_status_id;
    },
    async saveTypeStatus() {
      let currStatusName = this.convertReportStatus(this.selectedCompanyAuditRecord[this.type]?.report_status_id).name;
      let changeReportStatusName = this.convertReportStatus(this.auditRecord[this.type]?.report_status_id).name;
      const verify = swal.fire({
        icon: 'question',
        title: 'CHANGE REPORT STATUS',
        html: `CHANGING <strong> ${currStatusName} </strong> STATUS TO <strong> ${changeReportStatusName} </strong> STATUS `,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showCancelButton: true,
        cancelButtonText: 'CANCEL',
        confirmButtonText: 'PROCEED',
        confirmButtonColor: '#28a745',
        cancelButtonColor: '#dc3545'
      }).then(result => {
        return result;
      })
      if ((await verify).isConfirmed) {
        await this.doUpdate(true);
      }
    },
    async requestAndSetDataFromCms(type) {
      const crewListParam = {
        onboard_from: this.auditRecord[type].audit_date[0],
        onboard_to: this.auditRecord[type].audit_date[1],
        vesselName: this.vessel.name,
      };

      const response = await this.getCrewKeys(crewListParam);

      if (response.hasOwnProperty('errors')) {
        let errors = response.errors
        let error_message = '';
        error_message += '<ol style="text-align: left; font-size: 1em">';
        Object.keys(errors).forEach(key => {
          let err_msg = errors[key].join(',');
          error_message += '<li class="e-text-red"><small>* ' + err_msg + '</small></li>';
        });
        error_message += '</ol>';
        AlertService.errorAlert(error_message, 'GET CREW ONBOARD LISTS')
      } else {
        this.master[type] = response.crews.filter((crew) => crew.rank_id === 1);
        this.eng_dept_head[type] = response.crews.filter((crew) => crew.rank_id === 2);
        this.deck_dept_head[type] = response.crews.filter((crew) => crew.rank_id === 3);
      }
    },
    async getDataFromCms() {
      const activeTypes = this.getActiveTypes();
      swal.fire({...this.swalOptions, ...{title: 'ESTABLISHING CONNECTION TO CMS'}});
      swal.showLoading();

      for (const type of activeTypes) {
        // await this.requestAndSetDataFromCms(type);
      }
      swal.close();
    }
  },
  computed: {
    ports() {
      return this.portKeys.filter(port => port.country_id === this.auditRecord[this.type]?.audit_country_id)
    },
    auditDateHasChanges() {
      const originalData = {
        audit_date : [
          this.selectedCompanyAuditRecord[this.type]?.audit_date_start['date-picker'],
          this.selectedCompanyAuditRecord[this.type]?.audit_date_end['date-picker'],
        ],
      }

      const editedData = {
        audit_date : this.auditRecord[this.type].audit_date,
      }

      return DataService.checkIfChanged(originalData, editedData);
    },
    hasChanges() {
      let originalData = {
        // audit_type_2 : this.selectedCompanyAuditRecord[this.type]?.audit_type_2,
        audit_type_3 : this.selectedCompanyAuditRecord[this.type]?.audit_type_3,
        appointment_date : this.selectedCompanyAuditRecord[this.type]?.appointment_date['date-picker'],
        extension : parseInt(this.selectedCompanyAuditRecord[this.type]?.is_extended),
        extended_date : this.selectedCompanyAuditRecord[this.type]?.extended_date['date-picker'],
        audit_country_id : this.selectedCompanyAuditRecord[this.type]?.country_id,
        audit_port_id : this.selectedCompanyAuditRecord[this.type]?.port_id,
        lead_ids: this.filterAuditorsByPosition(this.selectedCompanyAuditRecord[this.type.toLowerCase()]?.auditors).leaders.map(leader => leader.id),
        members_ids: this.filterAuditorsByPosition(this.selectedCompanyAuditRecord[this.type.toLowerCase()]?.auditors).members.map(member => member.id),
        audit_master : this.selectedCompanyAuditRecord[this.type]?.audit_master,
        deck_dept_head : this.selectedCompanyAuditRecord[this.type]?.deck_dept_head,
        eng_dept_head : this.selectedCompanyAuditRecord[this.type]?.eng_dept_head
      }

      let editedData = {
        // audit_type_2 : this.auditRecord[this.type].audit_type_2,
        audit_type_3 : this.auditRecord[this.type].audit_type_3,
        appointment_date : this.auditRecord[this.type].appointment_date,
        extension : parseInt(this.auditRecord[this.type].extension),
        extended_date : this.auditRecord[this.type].extension_date,
        audit_country_id : this.auditRecord[this.type].audit_country_id,
        audit_port_id : this.auditRecord[this.type].audit_port_id,
        lead_ids : this.auditRecord[this.type].lead_ids,
        members_ids : this.auditRecord[this.type].members_ids,
        audit_master : this.auditRecord[this.type].audit_master,
        deck_dept_head : this.auditRecord[this.type].deck_dept_head,
        eng_dept_head : this.auditRecord[this.type].eng_dept_head
      }

      return this.auditDateHasChanges || DataService.checkIfChanged(originalData, editedData);
    },
    isTypeReadyToClose() {
      let extensionValidation = true;

      if (this.selectedCompanyAuditRecord[this.type.toLowerCase()]?.extension !== null && this.selectedCompanyAuditRecord[this.type.toLowerCase()]?.extension === 1) {
        extensionValidation = this.selectedCompanyAuditRecord[this.type.toLowerCase()]?.extended_date.human !== null
      }

      return (
        (extensionValidation) &&
        (this.selectedCompanyAuditRecord[this.type.toLowerCase()]?.audit_type_2.length > 0) &&
        (this.selectedCompanyAuditRecord[this.type.toLowerCase()]?.audit_type_3.length > 0) &&
        (this.selectedCompanyAuditRecord[this.type.toLowerCase()]?.audit_date_start.human !== null && this.selectedCompanyAuditRecord[this.type.toLowerCase()]?.audit_date_end.human !== null) &&
        (this.selectedCompanyAuditRecord[this.type.toLowerCase()]?.appointment_date.human !== null) &&
        (this.selectedCompanyAuditRecord[this.type.toLowerCase()]?.country_id !== null) &&
        (this.filterAuditorsByPosition(this.selectedCompanyAuditRecord[this.type.toLowerCase()]?.auditors).leaders.length !== 0)
      );
    },
  },
  async created() {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    this.setInitialActiveType();
    await this.setAuditReport();
    await this.addRequestKeys([
      'getReportStatusKeys'
    ]);
    await this.generateRequestKeys({});
    await this.setReportTypeStatusKeys(this.auditRecord);
    await this.getDataFromCms();
  },
  async beforeRouteLeave(to, from, next) {
    if (this.hasChanges === true) {
      if (await AlertService.cancelChanges() === true) {
        next();
      }
    } else {
      next();
    }
  }
}
</script>

<style scoped lang="scss">
.nav > li {
  margin: .5rem 0;
  padding: .5rem;
  border-radius: 5px;

  &:hover, &:hover * {
    background-color: white;
    color: #d3313c;
  }
}

.e-btn-grp > li {
  list-style: none;
  font-size: 13px;
  padding: 4px;
  border-radius: 3px;
  color: #d3313c;
  cursor: pointer;

  &:hover {
    background-color: #fbeaec;
  }

  * {
    color: #d3313c;
  }
}

#ModelNav {
  padding: 0;

  li > a {
    padding: .5rem 1rem;
    color: #435464;
  }

  li:hover a {
    border-bottom: 2px solid #d3313c;
    color: #d3313c;
  }
}

.router-link-active {
  border-bottom: 2px solid #d3313c;
  color: #d3313c;

  * {
    color: #d3313c;
  }
}

table td {
  padding: 2px !important;
}

.gray {
  color: #435464
}

.active_custom {
  background-color: #d3313c;
  color: #fff;
}
</style>
